<template>
  <div class="main-page">
    <div class="container" v-if="albumInfo">
      <div class="title-box">
        <span class="title">{{ albumInfo.title }}</span>
        <div class="viewCount">
          <i class="el-icon-view" />
          <span style="margin-left: 4px">{{ albumInfo.viewCount }}</span>
          <span style="margin-left: 14px">{{ getTime() }}</span>
          <span style="margin-left: 14px">
            <i class="el-icon-circle-close" style="color: red" />
            未经作者授权，禁止转载</span>
        </div>

        <div style="align-self:end;margin-top: -50px;display: flex;justify-content: center;align-items: center"
             @click="copyLink"
             class="btn">
          <i class="el-icon-share" style="font-size: 20px" />
          <span style="margin-left: 6px">分享</span>
        </div>
      </div>

      <div class="content-list-box">
        <div v-for="(item,itemIndex) in contentList" :key="item" :ref="'content_'+itemIndex">

        <template v-if="item.type === 'naviLayout'">
            <affix>
              <div class="tag-group" ref="tagsGrid">
                <div v-for="(naviItem,index) in naviList" :key="index"
                     :class="['tag-item',currentNaviItemIndex === index ? 'tag-item-selected' : '']"
                     @click="jumpNavi(naviItem,index)">{{ naviItem.title }}
                </div>
              </div>
            </affix>


          </template>

          <template v-if="item.type === 'h5Link'" >
            <div class="h5LinkItemGroup">
              <div v-for="(h5Item,h5Index) in item.dataList"
                   @click="clickLink(h5Item , item)"
                   :key="h5Item.link"
                   class="h5LinkItem"
                   :style="{'background-color':h5Colors[h5Index % h5Colors.length]}">
                {{ h5Item.title }}
              </div>
            </div>
          </template>

          <template v-if="item.type === 'image'">

<!--            <el-carousel trigger="click" height="400px" type="card" interval="0">-->
<!--              <el-carousel-item v-for="imageItem in item.dataList" :key="imageItem">-->
<!--                <el-image-->
<!--                  :src="imageItem"-->
<!--                  class="imageItem"-->
<!--                  :preview-src-list="item.dataList"-->
<!--                  fit="contain"-->
<!--                />-->
<!--              </el-carousel-item>-->
<!--            </el-carousel>-->
            <el-image
              v-for="imageItem in item.dataList"
              :key="imageItem"
              :src="imageItem"
              class="imageItem itemShadow"
              fit="widthFix"
              @click="handleRawData(item)"
            />

          </template>

          <template v-if="item.type === 'video'">
            <div style="display: flex;flex-direction: column;align-items: center">

              <video
                v-for="(videoItem,videoIndex) in item.dataList"
                :key="videoItem"
                :src="encodeURI(videoItem)"
                :autoplay="false"
                :controls="true"
                class="videoItem"
                :id="'video_' + itemIndex + '_' + videoIndex"
                :ref="'video_' + itemIndex + '_' + videoIndex"
                @play="handleVideoPlay(itemIndex,videoIndex,item.rawData)"
              />
            </div>
            <div
              v-if="!getVideoAccess(item.rawData)"
              class="videoItem empty-video" @click="handleVip">
              立刻开通会员，听专家为你解读
            </div>
          </template>


          <template v-if="item.type === 'text'">
            <div class="textItem itemShadow" @click="handleRawData(item)">
              <div class="taro_html" v-html="item.dataList[0]"></div>
            </div>
          </template>

          <template v-if="item.type === 'company'">
            <div class="itemBox">
              <company-item v-for="company in item.dataList" :company="company" :key="company" />
            </div>
          </template>


          <template v-if="item.type === 'album'">
            <div class="itemBox">
              <div v-for="subAlbum in item.dataList" :key="subAlbum">
                <album-card :album-info="subAlbum" display="row" />
              </div>
            </div>
          </template>

          <template v-if="item.type === 'file'">
            <div class="itemBox blueBox" style="padding-top: 1px !important;">
              <div v-for="fileItem in item.dataList"
                   :key="fileItem"
                   class="fileItem">
                <img class="fileImage" src="https://res.offertalks.cn/miniapp/icon-pdf-2.png" />
                <span class="fileName">{{ getFileName(fileItem) }}</span>
                <div class="btn" style="margin-top: 0 !important;align-self: center"
                     @click="openFile(fileItem)">查看
                </div>
              </div>

            </div>
          </template>


          <template v-if="item.type === 'user'">
            <div v-for="(user) in item.dataList"
                 :key="user"
                 class="userItem">
              <img class="user-image" :src="user.avatar" />
              <div style="margin-left: 10px;flex: 1;display: flex;flex-direction: column">
                <div style="display: flex;flex-direction: row">
                  <span class="user-name">{{ user.name }}</span>
                  <img class="vip-icon"
                       style="margin-left: 20px"
                       src="https://res.offertalks.cn/miniapp/icon-vip.png" />
                </div>
                <span class="user-tags">{{ user.tags }}</span>
              </div>
              <div class="btn" @click="contactUser">
                联系TA
              </div>
            </div>
          </template>


          <template v-if="item.type === 'operationBtn'">
            <div v-if="showVipBtn(item) && !vipPower">
              <div class="vip-card-layout" @click="handleVip">
                <div class="txt">{{"解锁&lt;"+'全部'+getAlbumTypeTxt(albumInfo.type)+'&gt;'}}</div>
<!--                <div class="btn">查看</div>-->
              </div>
            </div>
            <div v-if="hasExtraBtn(item)" @click="toActivity(hasExtraBtn(item))" class="activityBtn blueBox">
              发布需求
            </div>
          </template>


        </div>
      </div>


    </div>
    <div class="right-container">

    </div>
  </div>
</template>

<script>

import { getAlbumModuleDetail } from "@/apis/module";
import { formatDatetime } from "@/utils/dateutils";
import CompanyItem from "@/components/Article/CompanyItem";
import AlbumCard from "@/components/v2/AlbumCard";
import { mapActions, mapGetters } from "vuex";
import { getLoginDetail } from "@/apis/account";
import Affix from "@/components/affix.vue";
import AlbumType from "@/utils/AlbumType";

export default {
  components: { Affix, AlbumCard, CompanyItem },
  mixins:[AlbumType],
  data() {
    return {
      albumInfo: null,
      contentList: null,
      vipPower:false,
      currentNaviItemIndex:null,
      h5Colors : ([
        "#EFF6FFFF",
        "#FAF2E8FF",
        "#EEF7F0FF",
        "#FFF0F5FF",
      ])
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
    naviList() {
      var list = []
      for (let i = 0; i < this.contentList.length; i++) {
        if (this.contentList[i].rawData) {
          var title = this.contentList[i].rawData.naviTitle
          if (title) {
            list.push({
              index: i,
              title: title
            })
          }
        }
      }
      return list
    }
  },
  watch:{
    userInfo(){
      this.changeVipPower()
    },
    albumInfo(){
      this.changeVipPower()
    }
  },
  created() {
    this.getAlbumDetail();
    getLoginDetail().then(result => {
      this.UPDATE_USER_INFO(result.data);
      this.userInfo = result.data
    });
  },
  methods: {
    ...mapActions(["UPDATE_LOGIN_VISIABLE", "UPDATE_VIP_VISIBLE", "UPDATE_WECHAT_SERVICE_VISIBLE","UPDATE_USER_INFO"]),
    jumpNavi(item,itemIndex){
      this.currentNaviItemIndex = itemIndex;
      this.$refs['content_' + item.index][0].scrollIntoView({
        behavior:"smooth",
      })
    },

    handleRawData(item){
      if(item.rawData.vipContent === true){
        //需要会员
        if(!this.changeVipPower()){
          this.handleVip()
          return
        }
      }
      var link = item.rawData.link
      this.__toLink(link)
    },
    __toLink(link,newFrame=true){
      if (link && link.startsWith('https')) {
        if (link.indexOf('?') === -1) {
          link += '?'
        }
        link += '&opid=' + this.userInfo.id + '&fm=offertalks_weapp'
        if(!this.changeVipPower()){
          link += '&vippage=' + encodeURIComponent('https://www.offertalks.cn/album/detail?albumId=' + this.albumId)
        }
        console.log('link', link,newFrame)
        if(newFrame === true){
          window.open(link,'_blank')
        }else{
          // window.location.href = link
          //   window.history.replaceState(null , null , link)
          window.location.replace(link)
        }
      }
    },

    clickLink(h5Item , parentItem) {
      if(parentItem.rawData.vipContent === true){
        //需要会员
        if(!this.changeVipPower()){
          this.handleVip()
          return
        }
      }
      console.log('parentItem',parentItem)
      getLoginDetail().then(result => {
        this.UPDATE_USER_INFO(result.data);
        this.userInfo = result.data

        var link = h5Item.link
        this.__toLink(link)
      });
    },
    getVideoAccess(rawData) {
      console.log('rawDatarawData',rawData)
      if (rawData.vipContent !== true) {
        return true
      } else {
        return this.changeVipPower()
      }
    },
    handleVideoPlay(itemIndex, videoIndex, rawData) {
      var playVideo = 'video_' + itemIndex + '_' + videoIndex
      console.log('handleVideoPlayhandleVideoPlayhandleVideoPlay' , playVideo)
      if (!this.getVideoAccess(rawData)) {
        //无权限
        document.getElementById(playVideo).pause()
        this.handleVip()
      }


    },
    changeVipPower(){
      var hasPower = false

      if (this.userInfo && this.albumInfo) {

        if (this.userInfo.vipTypes && this.userInfo.vipTypes.indexOf(this.albumInfo.type) > -1) {
          hasPower = true
        }
      }

      this.vipPower = hasPower

      console.log('hasPower',hasPower)

      return hasPower


    },
    getAlbumDetail() {
      const albumId = decodeURIComponent(this.$route.query.albumId);
      getAlbumModuleDetail(albumId).then(res => {
        console.log(res.data);
        if (res.code === 9988) {
          this.handleVip()
          return
        }
        if(res.data.album['link']){
          this.__toLink(res.data.album['link'],false)
          return
        }
        this.albumInfo = res.data.album
        var contentList = res.data.module.contentList
        if (contentList) {
          contentList.forEach(item => {
            if (item.rawData) {
              item.rawData = JSON.parse(item.rawData)
            }
          })
        }
        this.contentList = contentList

      });
    },
    getTime() {
      return formatDatetime(new Date(this.albumInfo.updateTime));
    },
    getFileIcon(url) {
      let fileType = url.substring(url.lastIndexOf(".") + 1);
      let iconName = "file";
      console.log("fileType", fileType);
      if (["docx", "zip", "excel", "file", "pdf", "txt"].indexOf(fileType) > -1) {
        iconName = fileType;
      }
      if (["xlsx"].indexOf(fileType) > -1) {
        iconName = "excel";
      }
      return "https://res.offertalks.cn/feige-resource/icons/icon_" + iconName + ".png";
    },
    openFile(fileItem) {
      console.log(fileItem);
      getLoginDetail().then(result => {
        this.UPDATE_USER_INFO(result.data);
        this.userInfo = result.data
        if (!this.changeVipPower()) {
          //非会员
          // this.showPayInfo = true
          this.UPDATE_VIP_VISIBLE(true);
        } else {
          //会员
          window.open(fileItem);
        }
      });
    },
    getFileName(url) {
      var name = url.substring(url.lastIndexOf('/') + 1)
      const hasUUID = name.substring(name.lastIndexOf('-') + 1 , name.lastIndexOf("."))
      if(hasUUID && hasUUID.length === 32){
        //含有UUID
        name = name.replace('-'+hasUUID , '')
      }
      return name
    },
    contactUser() {
      // console.log(user)
      // navigator.clipboard.writeText(user.wechatId)
      //   .then(() => this.$message({ message: "已复制联系方式", type: "success" }));
      this.UPDATE_WECHAT_SERVICE_VISIBLE(true);

    },
    copyLink() {
      navigator.clipboard.writeText('岗位题库、简历模板、面试逐字稿、精选企业名单，行业专家辅导……\n' + window.location.href)
        .then(() => this.$message({ message: "已复制链接", type: "success" }));

    },
    handleVip() {
      this.UPDATE_VIP_VISIBLE(true);
    },
    showVipBtn(item){
      let extraBtnKey = null
      if(item.dataList){
        var keys = Object.keys(item.dataList[0])
        keys.forEach(key =>{
          if(key === 'vip' && item.dataList[0][key]){
            //判断是否是会员
            extraBtnKey = this.albumInfo.type
          }
        })
      }

      console.log('extraBtnKeyextraBtnKeyextraBtnKey',extraBtnKey)

      return extraBtnKey
    },
    hasExtraBtn(item){
      let extraBtnKey = null
      if(item.dataList){
        var keys = Object.keys(item.dataList[0])
        keys.forEach(key =>{
          if(key !== 'vip' && key !== 'sijiao' && key !== 'shequn'){
            if (item.dataList[0][key]) {
              //判断是否是会员
              extraBtnKey = key
            }
          }
        })
      }
      return extraBtnKey
    },
    toActivity(key) {
      this.$router.push({
        path: "/myRecommend",
        query: {
          key: key
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.main-page {
  padding: 10px 30px 50px 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    width: calc(69%);

    .title-box {
      width: 100%;
      background-color: #fff;
      padding: 24px 30px 16px 20px;
      display: flex;
      flex-direction: column;

      .title {
        font-family: PingFangSC, PingFang SC;
        font-weight: bold;
        font-size: 28px;
        color: #333333;
        line-height: 30px;
      }

      .viewCount {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        margin-top: 10px;
        line-height: 22px;
      }
    }

    .content-list-box {
      padding: 10px 0px 100px 0px;
      margin-top: 16px;
      //background-color: #fff;


      .imageItem {
        cursor: pointer;
        margin-top: 16px;
        text-align: center;
        width: 100%;
        height: 100%;

        /deep/ .el-image__inner {
          width: 100% !important;
          max-width: 100%;
          height: 100% !important;
          object-fit: contain;
        }
      }

      .h5LinkItemGroup{
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        flex-wrap: wrap;
        row-gap: 40px;
        column-gap: 40px;
        margin-bottom: 20px;

        .h5LinkItem{
          font-size: 28px;
          border: 2px solid #f0f0f0;
          cursor: pointer;
          font-weight: 500;
          font-family: PingFangSC, PingFang SC;
          padding: 20px 30px;
          border-radius: 50px;
          background-color: #008AFF;
          color: #333333FF;
        }
      }

      .vip-card-layout {
        position: fixed;
        bottom: 0;
        width: calc(69% - 100px);
        margin: auto;
        margin-bottom: 100px;
        cursor: pointer;
        z-index: 100;
        display: flex;
        text-align: center;
        padding: 0px 15px;
        border-radius: 60px;
        height: 60px;
        background-color: #F2CA8B;
        align-items: center;
        justify-content: center;


        .txt {
          font-size: 28px;
          font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
          font-weight: normal;
          color: #462A21;
          line-height: 21px;
          font-weight: 600;
        }

        .btn {
          margin-left: 40px;
          background: #653C2E;
          border-radius: 13px;
          font-size: 21px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #FEDC9C;
          text-align: center;
        }
      }

      .videoItem {
        box-sizing: border-box;
        width: 80%;
        margin-top: 16px;
      }

      .empty-video {
        width: 80%;
        margin-left: 10%;
        height: 60px;
        line-height: 60px;
        border-radius: 60px;
        font-weight: 600;
        background-color: #f5c385;
        color: #462A21;
        font-size: 24px;
        text-align: center;
        cursor: pointer;
      }

      .itemShadow{
        background-color: #fff;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom: 10px;
      }

      .userItem {
        margin-top: 16px;
        display: flex;

        .user-image {
          border-radius: 60px;
          width: 60px;
          height: 60px;
        }

        .user-name {
          margin-top: 6px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 24px;
          color: #000000;
          line-height: 21px;
        }

        .vip-icon {
          width: 130px;
          height: 30px;
          margin-right: 20px;
          cursor: pointer;
        }

        .user-tags {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #666666;
          line-height: 15px;
          margin-top: 14px;
        }


      }

      .blueBox {
        background-color: #0052FE;
        color: white;

        .fileItem .fileName {
          color: white;
        }
      }

      .activityBtn{
        border-radius: 5px;
        width: auto !important;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        padding: 20px;
        cursor: pointer;
        text-align: center;
      }

      .itemBox {
        line-height: 20px;
        border-radius: 10px;
        //background-color: #F7F7FA;
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        //box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
        //margin: 16px 20px 0px 20px;
        //padding: 10px 20px;
        margin-top: 16px;
        border: 1px solid #EBEBEB;
        padding: 0 20px;
      }

      .itemDiv {
        //width: 100%;
        //height: 12px;
        //background-color: #f6f6f6;
      }


      .albumItem {
        //padding: 0 20px;
        //margin-top: 16px;

        .albumRecommendTitle {

          color: rgba(16, 16, 16, 100);
          font-size: 25px;
          text-align: left;
          font-family: PingFangSC-regular;
        }

        .albumImage {
          width: 100%;
          border-radius: 13px 13px 0px 0px;
        }

        .albumTitle {
          color: rgba(16, 16, 16, 100);
          font-size: 16px;
          text-align: left;
          font-family: PingFangSC-regular;
          margin: 15px 15px 0 15px;
        }
      }

      .textItem {
        cursor: pointer;
        padding: 20px;
        font-size: 20px;
        margin-top: 10px;
        line-height: 28px;

        .tag-more-view {
          display: flex;
          font-size: 18px;
          font-weight: bold;
          padding: 10px 0;
        }

        //.h5-h4,
        //.h5-p,
        //.h5-blockquote,
        //.h5-ul,
        //.h5-fieldset,
        //.h5-form,
        //.h5-ol,
        //.h5-dl,
        //.h5-dir,
        //.h5-menu {
        //  margin: 0 !important;
        //}
      }

      //.taro_html .h5-p {
      //  //margin: 5px 0 0px 0 !important;
      //  font-size: 15px;
      //  line-height: 28px;
      //}
      //.taro_html .h5-li{
      //  margin-inline-start: 20px;
      //  line-height: 28px;
      //  font-size: 15px;
      //}


      .box-title {
        margin-top: 6px;
        margin-left: 20px;
        color: rgba(16, 16, 16, 100);
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        font-weight: bold;
      }


      .kefu-header {
        display: flex;
        background-color: rgba(247, 247, 247, 1);
        padding: 10px;

        .huajie-avatar {
          width: 44px;
          height: 44px;
        }

        .name {
          color: rgba(16, 16, 16, 100);
          font-size: 15px;
          line-height: 15px;
          text-align: left;
          font-family: PingFangSC-regular;
        }

        .online {
          color: rgba(39, 177, 72, 100);
          font-size: 13px;
          line-height: 13px;
          text-align: left;
          font-family: PingFangSC-regular;
        }

        .content {
          margin-top: 10px;
          color: rgba(177, 177, 177, 100);
          line-height: 13px;
          font-size: 13px;
          text-align: left;
          font-family: PingFangSC-regular;
        }

        .right-icon {
          align-content: flex-end;
        }


      }

      .fileItem {
        margin-top: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .fileImage {
          width: 40px;
          height: 40px;
        }

        .fileName {
          align-self: center;
          margin-left: 10px;
          color: rgba(16, 16, 16, 100);
          font-size: 18px;
          align-content: center;
          font-weight: 600;
          font-family: PingFangSC-regular;
          flex: 1;
        }
      }


    }

    .btn {
      width: 100px;
      height: 50px;
      background: #0052FE;
      border-radius: 6px;
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 50px;
      font-style: normal;
      cursor: pointer;
    }

  }

  .right-container {
    display: none;
    flex-direction: column;
    width: 29%;
  }

}

.tag-group {
  margin-left: -30px;
  margin-right: -30px;
  background-color: #FFF;
  padding: 20px 30px;
  display: flex;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;

  .tag-item {
    background-color: #f6f6f6;
    padding: 12px 30px;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: normal;
    font-size: 18px;
    color: #333333;
    line-height: 24px;
    text-align: right;
    font-style: normal;
    cursor: pointer;
  }

  .tag-item-selected {
    color: #0052FE;
    background-color: rgba(0, 82, 254, .1) !important;
  }
}
</style>
